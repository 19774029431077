<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div class="swiperBox">
      

      <!-- <div style="position: absolute;top: 10px;width: 100%;"> -->
        <div class="title">宜宾市拓慧信息有限公司</div>
      <!-- </div> -->
      <div class="swiper swiper1">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in imgList" :key="item.id">
            <img :src="item.url" alt="" style="width: 100%; height: 100%" />
          </div>
        </div>
      </div>
    </div>
    <div class="product">
      <div class="product-name">产品中心</div>
      <div class="product-name1">PRODUCT &nbsp; CENTER</div>
      <div class="product-tab"></div>
      <div class="swiper swiper2">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in imgList1" :key="item.id">
            <img :src="item.url" alt="" />
            <div class="swiper-name">{{ item.name }}</div>
            <div class="swiper-text">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product-About">
      <div class="product-name">关于我们</div>
      <div class="product-name1">ABOUT &nbsp; US</div>
      <div class="product-box">
        <div>
          <img src="../assets/6.png" alt="" />
          <span class="name">公司简介</span>
          <span>智慧校园整体解决方案提供商</span>
          <span>核心自研技术、产品创新能力</span>
          <span>高新技术企业、双软认证企业...</span>
        </div>
        <div>
          <img src="../assets/5.png" alt="" />
          <span class="name">企业文化</span>
          <span>与时代共同进步</span>
          <span>与客户共创价值</span>
          <span>与团队共同成长</span>
        </div>
        <div>
          <img src="../assets/7.png" alt="" />
          <span class="name">企业优势</span>
          <span>系统引领、终端配套</span>
          <span>技术研发核心竞争力</span>
          <span>售前、售后服务保障</span>
        </div>
      </div>
    </div>
    <div class="introduction">
      <div class="introduction-box">
        <div style="line-height:40px">
          <span>电话：19827424339</span>
          <span>邮箱：ybthit@qq.com</span>
          <span>地址：四川宜宾兴文县古宋镇光明坝兴文二中校区综合楼三楼</span>
          <span>蜀ICP备16028672号-3</span>
        </div>
        <div style="text-align:center">
          <img src="../assets/8.jpg" style="height: 130px;" alt="">
          <span>官方公众号</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { Swiper, EffectFade, Autoplay } from "swiper";
Swiper.use([EffectFade, Autoplay]);
import "../../node_modules/swiper/swiper-bundle.min.css";
export default {
  name: "Home",
  components: {
    // HelloWorld
  },
  data() {
    return {
      imgList: [
        { id: 3, url: require("../assets/3.jpg") },
        { id: 4, url: require("../assets/4.jpg") }
      ],
      imgList1: [
        { id: 2, url: require("../assets/9.png"),name:'智汇兴文',text:'宣传及时信息，推广智慧应用、受理公众监督、提供便民服务、展示共享数据' },
        { id: 3, url: require("../assets/3.jpg"),name:'统一数据管理平台',text:'该系统可以完成对整个政务网的身份和权限管理，保证各应用系统基于统一的模式、集中的环境开发与升级，一方面降低了系统整体运行的维护成本，另一方面保证了整个校园系统能够随着平台的升级而同步升级，方便使用和管理，也保证了整个系统的先进性与安全性。' },
        { id: 4, url: require("../assets/4.jpg"),name:'营业执照',text:'宜宾市拓慧信息科技有限责任公司，成立于2016年，位于四川省宜宾市，是一家以从事软件和信息技术服务业为主的企业。' }
      ],
    };
  },
  mounted() {
    this.swiperInfo();
    this.swiperInfo1();
  },
  methods: {
    swiperInfo() {
      new Swiper(".swiper1", {
        // autoplay: true, //可选选项，自动滑动
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
        },
        speed: 1500,
        loop: true,
        // noSwiping:true,
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
      });
    },
    swiperInfo1() {
      new Swiper(".swiper2", {
        slidesPerView: 3,
        autoplay: {
          delay: 3500,
          // disableOnInteraction: false,
        },
        speed: 1500,
        // noSwiping:true,
        // effect: 'fade',
        // fadeEffect:{
        //   crossFade:true
        // }
      });
    },
  },
};
</script>
<style scoped lang="less">
.title {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0px;
  padding: 20px;
  z-index: 20;
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  left: 0;
  margin: 0 auto;
  right: 0;
  letter-spacing: 10px;
}
.swiperBox {
  position: relative;
  height: 90vh;
  width: 100%;
  .swiper1 {
    height: 100%;
    width: 100%;
    .swiper-slide {
      height: 100%;
      width: 100%;
      // background-color: #a8a8a8;
    }
  }
}
.product {
  width: 1450px;
  margin: 60px auto;
  // background-color: tan;
  .swiper2 {
    height: 100%;
    // width: 100%;
    .swiper-slide {
      height: 100%;
      width: 100%;
      img {
        height: 324px;
        width: calc(100% - 30px);
      }
      .swiper-name {
        font-size: 16px;
        font-weight: 600;
        margin: 10px auto;
        color: black;
      }
      .swiper-text {
        width: 400px;
        color: #999;
        height: 85px;
        line-height: 40px;
        text-align: center;
        margin: 0 auto;
        overflow: hidden;
        word-break: break-word;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        display: -webkit-box;
        text-overflow: ellipsis;
      }
    }
  }
  .product-name {
    font-size: 35px;
    font-weight: 600;
    color: black;
    letter-spacing: 4px;
  }
  .product-name1 {
    font-size: 16px;
    font-weight: 600;
    color: #e8e8e8;
    margin: 20px auto 80px auto;
  }
}
.product-About {
  max-width: 1450px;
  margin: 60px auto;
  // background-color: tan;
  .product-name {
    font-size: 35px;
    font-weight: 600;
    color: black;
    letter-spacing: 4px;
  }
  .product-name1 {
    font-size: 16px;
    font-weight: 600;
    color: #e8e8e8;
    margin: 20px auto 80px auto;
  }
  .product-box {
    width: 100%;
    height: 500px;
    display: flex;
    div {
      // background: #999;
      width: calc(100% / 3 - 100px);
      height: 100%;
      margin: 0 auto;
      border-radius: 8px;
      box-shadow: 0 0 25px 2px #e8e8e8;
      transition: All 0.4s ease-in-out;
      -webkit-transition: All 0.4s ease-in-out;
      -moz-transition: All 0.4s ease-in-out;
      -o-transition: All 0.4s ease-in-out;
      img {
        width: 55%;
        margin: 40px auto 40px auto;
      }
      span {
        display: block;
        color: #989898;
        line-height: 30px;
      }
      .name {
        font-size: 26px;
        margin-bottom: 30px;
        color: black;
        font-weight: 600;
      }
    }
    div:hover {
      box-shadow: 0 0 25px 2px #19a7fd;
      transform: translate(0, -10px);
    }
  }
}
.introduction {
  // height: 300px;
  background-color: #a8a8a8;
  color: #fff;

}
.introduction-box{
  display: flex;
  width: 1050px;
  margin: 0 auto;
  height: 100%;
  text-align: left;
  padding: 40px;
  div{
    width: 50%;
  }
  span{
    display: block;
  }
}
</style>
